import { MA_APP_IDS, withMembersArea } from '@wix/members-area-integration-kit';
import { ChallengesPlatform } from './editor/ChallengesPlatform';

import AppManifest, {
  AppManagerEvent,
  PageActionsEvent,
} from './editor/types/manifest';

let platform: ChallengesPlatform;

const editorApi = {
  editorReady: async (editorSDK, appDefinitionId, options, flowAPI) => {
    const { firstInstall, origin } = options;
    const isADI = origin?.type?.toLowerCase().includes('adi');
    const isEditorWizardCreationFlow =
      options.origin?.info?.type === 'SILENT_INSTALL_SITE_CREATION' ||
      options.origin?.info?.type === 'SILENT_INSTALL';

    platform = new ChallengesPlatform(
      editorSDK,
      appDefinitionId,
      flowAPI,
      isADI,
      isEditorWizardCreationFlow,
    );

    const isChallengesInstalled = await platform.isChallengesInstalled();

    if (!isChallengesInstalled) {
      return;
    }

    const installTransaction = async () => {
      await platform.setupProgramsPages({
        isFirstInstall: firstInstall,
      });
      await platform.setupMA({
        isFirstInstall: firstInstall,
      });
    };

    await editorSDK.document.transactions.runAndWaitForApproval(
      appDefinitionId,
      installTransaction,
    );
  },

  getAppManifest: async ({ appManifestBuilder }): Promise<AppManifest> => {
    return platform.getManifest({ appManifestBuilder });
  },

  onEvent: async (event) => {
    switch (event.eventType) {
      case PageActionsEvent.REMOVE:
        return platform.deleteApp(event.eventPayload);
      case PageActionsEvent.APP_ACTION:
      case AppManagerEvent.OPEN_DASHBOARD:
        return platform.openDashboard(
          event?.eventPayload?.actionId || AppManagerEvent.OPEN_DASHBOARD,
        );
      default:
        return;
    }
  },

  handleAction: async (event) => {
    const { type, payload } = event;

    console.log('>: handleAction call', event);

    if (type === 'appInstalled') {
      if (!platform?.appDefId) {
        return;
      }

      try {
        switch (payload.appDefinitionId) {
          case platform?.appDefId:
            console.log('>: handleAction call from oprog', event);
            await platform.installDependencies();
            break;
          default:
        }
      } catch (e) {
        console.error('[Challenges]: error handle action:', e);
        throw e;
      }
    } else if (type === 'migrate') {
      if (!platform?.appDefId) {
        return;
      }

      await platform.handleMigration(payload);
    } else if (type === 'removeAppCompleted') {
      // todo: platform.removeProgramsApplication();
    }

    console.log('>: handleAction call ended', event);
  },
};

const editorApiWithMA = withMembersArea(editorApi, {
  disableADI: false,
  installAutomatically: false,
  membersAreaApps: [
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.MY_PROGRAMS,
    MA_APP_IDS.ABOUT,
  ],
});

export const editorReady =
  editorApiWithMA.editorReady as typeof editorApi.editorReady;
export const getAppManifest =
  editorApiWithMA.getAppManifest as typeof editorApi.getAppManifest;
export const onEvent = editorApiWithMA.onEvent as typeof editorApi.onEvent;
export const handleAction = editorApiWithMA.handleAction;
